import request from '../_api/request'

const state = {
  product: {
    data: [],
    total: 0
  },
  productTags: [],
  stock_request: {
    data: [],
    total: 0
  },
  categories: [
    {
      logo: 'https://www.car-logos.org/wp-content/uploads/2022/08/abarth1.png',
      name: 'Abarth'
    },
    {
      logo: 'https://www.car-logos.org/wp-content/uploads/2022/08/ac-cars.png',
      name: 'AC'
    },
    {
      logo: 'https://www.car-logos.org/wp-content/uploads/2022/08/acura.png',
      name: 'Acura'
    },
    {
      logo: 'https://www.car-logos.org/wp-content/uploads/2022/08/aixam.png',
      name: 'Aixam'
    },
    {
      logo: 'https://www.car-logos.org/wp-content/uploads/2022/08/alfa_romeo.png',
      name: 'Alfa Romeo'
    },
    {
      logo: 'https://www.car-logos.org/wp-content/uploads/2022/08/ariel.png',
      name: 'Ariel'
    },
    {
      logo: 'https://www.car-logos.org/wp-content/uploads/2011/10/arrinera-logo.png',
      name: 'Arrinera'
    },
    {
      logo: 'https://www.car-logos.org/wp-content/uploads/2022/08/aston_martin.png',
      name: 'Aston Martin'
    },
    {
      logo: 'https://www.car-logos.org/wp-content/uploads/2022/08/audi.png',
      name: 'Audi'
    },
    {
      logo: 'https://www.car-logos.org/wp-content/uploads/2022/08/bentley.png',
      name: 'Bentley'
    },
    {
      logo: 'https://www.car-logos.org/wp-content/uploads/2022/08/bmw.png',
      name: 'BMW'
    },
    {
      logo: 'https://www.car-logos.org/wp-content/uploads/2022/08/bugatti.png',
      name: 'Bugatti'
    },
    {
      logo: 'https://www.car-logos.org/wp-content/uploads/2022/08/buick.png',
      name: 'Buick'
    },
    {
      logo: 'https://www.car-logos.org/wp-content/uploads/2022/08/cadillac.png',
      name: 'Cadillac'
    },
    {
      logo: 'https://www.car-logos.org/wp-content/uploads/2022/08/caterham.png',
      name: 'Caterham'
    },
    {
      logo: 'https://www.car-logos.org/wp-content/uploads/2022/08/chevrolet.png',
      name: 'Chevrolet'
    },
    {
      logo: 'https://www.car-logos.org/wp-content/uploads/2022/08/chrysler.png',
      name: 'Chrysler'
    },
    {
      logo: 'https://www.car-logos.org/wp-content/uploads/2022/08/citroen.png',
      name: 'Citroën'
    },
    {
      logo: 'https://www.car-logos.org/wp-content/uploads/2022/08/corvette.png',
      name: 'Corvette'
    },
    {
      logo: 'https://www.car-logos.org/wp-content/uploads/2022/08/dacia.png',
      name: 'Dacia'
    },
    {
      logo: 'https://www.car-logos.org/wp-content/uploads/2022/08/daf.png',
      name: 'Daf'
    },
    {
      logo: 'https://www.car-logos.org/wp-content/uploads/2022/08/daihat.png',
      name: 'Daihatsu'
    },
    {
      logo: 'https://www.car-logos.org/wp-content/uploads/2022/08/dodge.png',
      name: 'Dodge'
    },
    {
      logo: 'https://www.car-logos.org/wp-content/uploads/2011/10/drmotor.png',
      name: 'DR Motor'
    },
    {
      logo: 'https://www.car-logos.org/wp-content/uploads/2022/08/elfin.png',
      name: 'Elfin'
    },
    {
      logo: 'https://www.car-logos.org/wp-content/uploads/2022/08/ferrari.png',
      name: 'Ferrari'
    },
    {
      logo: 'https://www.car-logos.org/wp-content/uploads/2022/08/fiat.png',
      name: 'Fiat'
    },
    {
      logo: 'https://www.car-logos.org/wp-content/uploads/2022/08/ford.png',
      name: 'Ford'
    },
    {
      logo: 'https://www.car-logos.org/wp-content/uploads/2022/08/gaz.png',
      name: 'Gaz'
    },
    {
      logo: 'https://www.car-logos.org/wp-content/uploads/2022/08/geely.png',
      name: 'Geely'
    },
    {
      logo: 'https://www.car-logos.org/wp-content/uploads/2022/08/gillet.png',
      name: 'Gillet'
    },
    {
      logo: 'https://www.car-logos.org/wp-content/uploads/2022/08/ginetta.png',
      name: 'Ginetta'
    },
    {
      logo: 'https://www.car-logos.org/wp-content/uploads/2022/08/gm.png',
      name: 'General Motors'
    },
    {
      logo: 'https://www.car-logos.org/wp-content/uploads/2022/08/gmc.png',
      name: 'GMC'
    },
    {
      logo: 'https://www.car-logos.org/wp-content/uploads/2013/10/Great-Wall.png',
      name: 'Great Wall'
    },
    {
      logo: 'https://www.car-logos.org/wp-content/uploads/2022/08/gumpert.png',
      name: 'Gumpert'
    },
    {
      logo: 'https://www.car-logos.org/wp-content/uploads/2011/10/hennessey.png',
      name: 'Hennessey logo'
    },
    {
      logo: 'https://www.car-logos.org/wp-content/uploads/2011/10/holden.png',
      name: 'Holden'
    },
    {
      logo: 'https://www.car-logos.org/wp-content/uploads/2022/08/honda.png',
      name: 'Honda'
    },
    {
      logo: 'https://www.car-logos.org/wp-content/uploads/2022/08/hummer.png',
      name: 'Hummer'
    },
    {
      logo: 'https://www.car-logos.org/wp-content/uploads/2022/08/hyundai.png',
      name: 'Hyundai'
    },
    {
      logo: 'https://www.car-logos.org/wp-content/uploads/2022/08/inf.png',
      name: 'Infiniti'
    },
    {
      logo: 'https://www.car-logos.org/wp-content/uploads/2022/08/isuzu.png',
      name: 'Isuzu'
    },
    {
      logo: 'https://www.car-logos.org/wp-content/uploads/2022/08/jagu.png',
      name: 'Jaguar'
    },
    {
      logo: 'https://www.car-logos.org/wp-content/uploads/2022/08/jeep.png',
      name: 'Jeep'
    },
    {
      logo: 'https://www.car-logos.org/wp-content/uploads/2022/08/joss.png',
      name: 'Joss'
    },
    {
      logo: 'https://www.car-logos.org/wp-content/uploads/2022/08/kia.png',
      name: 'Kia'
    },
    {
      logo: 'https://www.car-logos.org/wp-content/uploads/2022/08/koenigsegg.png',
      name: 'Koenigsegg'
    },
    {
      logo: 'https://www.car-logos.org/wp-content/uploads/2022/08/lada.png',
      name: 'Lada'
    },
    {
      logo: 'https://www.car-logos.org/wp-content/uploads/2022/08/lamb.png',
      name: 'Lamborghini'
    },
    {
      logo: 'https://www.car-logos.org/wp-content/uploads/2022/08/lancia.png',
      name: 'Lancia'
    },
    {
      logo: 'https://www.car-logos.org/wp-content/uploads/2022/08/land-rover.png',
      name: 'Land Rover'
    },
    {
      logo: 'https://www.car-logos.org/wp-content/uploads/2022/08/lexus.png',
      name: 'Lexus'
    },
    {
      logo: 'https://www.car-logos.org/wp-content/uploads/2022/08/linc.png',
      name: 'Lincoln'
    },
    {
      logo: 'https://www.car-logos.org/wp-content/uploads/2022/08/lotus.png',
      name: 'Lotus'
    },
    {
      logo: 'https://www.car-logos.org/wp-content/uploads/2011/11/luxgen-logo.png',
      name: 'Luxgen'
    },
    {
      logo: 'https://www.car-logos.org/wp-content/uploads/2011/11/mahindra.png',
      name: 'Mahindra'
    },
    {
      logo: 'https://www.car-logos.org/wp-content/uploads/2011/12/Maruti_Suzuki.png',
      name: 'Maruti Suzuki'
    },
    {
      logo: 'https://www.car-logos.org/wp-content/uploads/2022/08/mase.png',
      name: 'Maserati'
    },
    {
      logo: 'https://www.car-logos.org/wp-content/uploads/2022/08/maybach.png',
      name: 'Maybach'
    },
    {
      logo: 'https://www.car-logos.org/wp-content/uploads/2022/08/mazda.png',
      name: 'Mazda'
    },
    {
      logo: 'https://www.car-logos.org/wp-content/uploads/2022/08/mclaren.png',
      name: 'Mclaren'
    },
    {
      logo: 'https://www.car-logos.org/wp-content/uploads/2022/08/marchedrs.png',
      name: 'Mercedes'
    },
    {
      logo: 'https://www.car-logos.org/wp-content/uploads/2022/08/mg.png',
      name: 'MG'
    },
    {
      logo: 'https://www.car-logos.org/wp-content/uploads/2022/08/mini.png',
      name: 'Mini'
    },
    {
      logo: 'https://www.car-logos.org/wp-content/uploads/2022/08/mitub.png',
      name: 'Mitsubishi'
    },
    {
      logo: 'https://www.car-logos.org/wp-content/uploads/2022/08/morgan.png',
      name: 'Morgan Motor'
    },
    {
      logo: 'https://www.car-logos.org/wp-content/uploads/2022/08/mustang.png',
      name: 'Mustang logo'
    },
    {
      logo: 'https://www.car-logos.org/wp-content/uploads/2022/08/nissan.png',
      name: 'Nissan'
    },
    {
      logo: 'https://www.car-logos.org/wp-content/uploads/2022/08/noble.png',
      name: 'Noble'
    },
    {
      logo: 'https://www.car-logos.org/wp-content/uploads/2022/08/opel.png',
      name: 'Opel'
    },
    {
      logo: 'https://www.car-logos.org/wp-content/uploads/2022/08/pagani.png',
      name: 'Pagani'
    },
    {
      logo: 'https://www.car-logos.org/wp-content/uploads/2022/08/panoz.png',
      name: 'Panoz'
    },
    {
      logo: 'https://www.car-logos.org/wp-content/uploads/2022/08/perodua.png',
      name: 'Perodua'
    },
    {
      logo: 'https://www.car-logos.org/wp-content/uploads/2022/08/peug.png',
      name: 'Peugeot'
    },
    {
      logo: 'https://www.car-logos.org/wp-content/uploads/2022/08/piaggio.png',
      name: 'Piaggio'
    },
    {
      logo: 'https://www.car-logos.org/wp-content/uploads/2022/08/pininfarina.png',
      name: 'Pininfarina'
    },
    {
      logo: 'https://www.car-logos.org/wp-content/uploads/2022/08/porsche.png',
      name: 'Porsche'
    },
    {
      logo: 'https://www.car-logos.org/wp-content/uploads/2022/08/proton.png',
      name: 'Proton'
    },
    {
      logo: 'https://www.car-logos.org/wp-content/uploads/2022/08/renault.png',
      name: 'Renault'
    },
    {
      logo: 'https://www.car-logos.org/wp-content/uploads/2022/08/reva.png',
      name: 'Reva'
    },
    {
      logo: 'https://www.car-logos.org/wp-content/uploads/2012/01/rimac-150x150.png',
      name: 'Rimac'
    },
    {
      logo: 'https://www.car-logos.org/wp-content/uploads/2022/08/rolls-royce.png',
      name: 'Rolls Royce'
    },
    {
      logo: 'https://www.car-logos.org/wp-content/uploads/2022/08/ruf.png',
      name: 'Ruf logo'
    },
    {
      logo: 'https://www.car-logos.org/wp-content/uploads/2022/08/saab.png',
      name: 'Saab'
    },
    {
      logo: 'https://www.car-logos.org/wp-content/uploads/2022/08/scania.png',
      name: 'Scania'
    },
    {
      logo: 'https://www.car-logos.org/wp-content/uploads/2022/08/scion.png',
      name: 'Scion'
    },
    {
      logo: 'https://www.car-logos.org/wp-content/uploads/2022/08/seat.png',
      name: 'Seat'
    },
    {
      logo: 'https://www.car-logos.org/wp-content/uploads/2011/10/shelby.png',
      name: 'Shelby'
    },
    {
      logo: 'https://www.car-logos.org/wp-content/uploads/2022/08/skoda.png',
      name: 'Skoda'
    },
    {
      logo: 'https://www.car-logos.org/wp-content/uploads/2022/08/smart.png',
      name: 'Smart'
    },
    {
      logo: 'https://www.car-logos.org/wp-content/uploads/2022/08/spyker.png',
      name: 'Spyker'
    },
    {
      logo: 'https://www.car-logos.org/wp-content/uploads/2022/08/ssangyong.png',
      name: 'Ssangyong'
    },
    {
      logo: 'https://www.car-logos.org/wp-content/uploads/2011/10/ssc.png',
      name: 'SSC'
    },
    {
      logo: 'https://www.car-logos.org/wp-content/uploads/2022/08/subaru.png',
      name: 'Subaru'
    },
    {
      logo: 'https://www.car-logos.org/wp-content/uploads/2022/08/suzuki.png',
      name: 'Suzuki'
    },
    {
      logo: 'https://www.car-logos.org/wp-content/uploads/2022/08/tata.png',
      name: 'Tata'
    },
    {
      logo: 'https://www.car-logos.org/wp-content/uploads/2022/08/tatra.png',
      name: 'Tatra'
    },
    {
      logo: 'https://www.car-logos.org/wp-content/uploads/2022/08/tesla.png',
      name: 'Tesla'
    },
    {
      logo: 'https://www.car-logos.org/wp-content/uploads/2022/08/toyota.png',
      name: 'Toyota'
    },
    {
      logo: 'https://www.car-logos.org/wp-content/uploads/2022/08/1176359_187686584745478_1792749640_n.jpg',
      name: 'Tramontana'
    },
    {
      logo: 'https://www.car-logos.org/wp-content/uploads/2022/08/troller.png',
      name: 'Troller'
    },
    {
      logo: 'https://www.car-logos.org/wp-content/uploads/2022/08/tvr.png',
      name: 'TVR'
    },
    {
      logo: 'https://www.car-logos.org/wp-content/uploads/2022/08/uaz.png',
      name: 'UAZ'
    },
    {
      logo: 'https://www.car-logos.org/wp-content/uploads/2022/08/vandenbrink_design.png',
      name: 'Vandenbrink'
    },
    {
      logo: 'https://www.car-logos.org/wp-content/uploads/2022/08/vauxhall.png',
      name: 'Vauxhall'
    },
    {
      logo: 'https://www.car-logos.org/wp-content/uploads/2022/08/vector_motors.png',
      name: 'Vector Motors'
    },
    {
      logo: 'https://www.car-logos.org/wp-content/uploads/2022/08/venturi.png',
      name: 'Venturi'
    },
    {
      logo: 'https://www.car-logos.org/wp-content/uploads/2022/08/volkswagen.png',
      name: 'Volkswagen'
    },
    {
      logo: 'https://www.car-logos.org/wp-content/uploads/2022/08/volvo.png',
      name: 'Volvo'
    },
    {
      logo: 'https://www.car-logos.org/wp-content/uploads/2022/08/wiesmann.png',
      name: 'Wiesmann'
    },
    {
      logo: 'https://www.car-logos.org/wp-content/uploads/2022/08/zagato.png',
      name: 'Zagato'
    },
    {
      logo: 'https://www.car-logos.org/wp-content/uploads/2022/08/zaz.png',
      name: 'Zaz'
    },
    {
      logo: 'https://www.car-logos.org/wp-content/uploads/2022/08/zil.png',
      name: 'Zil'
    }
  ]
}

const mutations = {
  SET_PRODUCT (state, v) {
    state.product.data = v.data || []
    state.product.total = parseInt(v.total) || 0
  },
  SET_PRODUCT_TAGS (state, v) {
    state.productTags = v || []
  },
  SET_STOCK_REQUEST (state, v) {
    state.stock_request.data = v.data || []
    state.stock_request.total = parseInt(v.total) || 0
  }
}

const actions = {
  async PRODUCT_GET (context, query) {
    context.dispatch('PRODUCT_TAGS_GET')
    return request.get('product' + (query || '')).then((res) => {
      if (res.status) {
        context.commit('SET_PRODUCT', { data: res.data.data.data, total: parseInt(res.data.data.total) })
      } else {
        context.commit('SET_PRODUCT', { data: [], total: 0 })
      }
      return res
    }).catch((e) => {
      return {
        status: false,
        data: e
      }
    })
  },
  async PRODUCT_TAGS_GET (context, query) {
    query = query ? query + '&store=' + context.rootState.storeActive : ('?store=' + context.rootState.storeActive)
    return request.get('product/tags' + (query || '')).then((res) => {
      if (res.status) {
        context.commit('SET_PRODUCT_TAGS', res.data.data)
      } else {
        context.commit('SET_PRODUCT_TAGS', [])
      }
      return res
    }).catch((e) => {
      return {
        status: false,
        data: e
      }
    })
  },
  async PRODUCT_GET_ONE (context, pid) {
    return request.get('product/' + pid).then((res) => {
      return res
    }).catch((e) => {
      return {
        status: false,
        data: e
      }
    })
  },
  async PRODUCT_ITEM_PRICING (context, id) {
    return request.get('product/item-pricing/' + (id || 0)).then((res) => {
      return res
    }).catch((e) => {
      return {
        status: false,
        data: e
      }
    })
  },
  async PRODUCT_ITEM_PRICING_CUSTOMER (context, id) {
    return request.get('product/item-pricing-by-customer/' + (id || 0)).then((res) => {
      return res
    }).catch((e) => {
      return {
        status: false,
        data: e
      }
    })
  },
  async PRODUCT_ITEM_PRICING_CUSTOMER_ADD (context, params) {
    return request.post('product/item-pricing-by-customer-add', params).then((res) => {
      return res
    }).catch((e) => {
      return {
        status: false,
        data: e
      }
    })
  },
  async PRODUCT_ITEM_PRICING_CUSTOMER_CLEAR (context, id) {
    return request.post('product/item-pricing-by-customer-clear/' + (id || 0)).then((res) => {
      return res
    }).catch((e) => {
      return {
        status: false,
        data: e
      }
    })
  },
  async CHECK_ITEM_PRICE (context, params) {
    return request.get('product/item-pricing-check/' + (params)).then((res) => {
      return res
    }).catch((e) => {
      return {
        status: false,
        data: e
      }
    })
  },
  async PRODUCT_ITEM_PRICING_PROCESS (context, params) {
    return request.post('product/item-pricing', params).then((res) => {
      return res
    }).catch((e) => {
      return {
        status: false,
        data: e
      }
    })
  },
  async PRODUCT_ITEM_PRICING_DELETE (context, data) {
    return request.delete('product/item-pricing/' + (data.customer || 0) + '/' + (data.itemid || 0)).then((res) => {
      return res
    }).catch((e) => {
      return {
        status: false,
        data: e
      }
    })
  },
  async PRODUCT_TAGS_PROCESS (context, params) {
    return request.post('product/tags', params).then((res) => {
      return res
    }).catch((e) => {
      return {
        status: false,
        data: e
      }
    })
  },
  async PRODUCT_ITEM_PROCESS (context, params) {
    return request.post('product/item', params).then((res) => {
      return res
    }).catch((e) => {
      return {
        status: false,
        data: e
      }
    })
  },
  async PRODUCT_PROCESS (context, params) {
    return request.post('product', params).then((res) => {
      return res
    }).catch((e) => {
      return {
        status: false,
        data: e
      }
    })
  },
  async STOCK_REQUEST_GET (context, query) {
    return request.get('product/stock-request' + (query || '')).then((res) => {
      if (res.status) {
        context.commit('SET_STOCK_REQUEST', { data: res.data.data.data, total: parseInt(res.data.data.total) })
      } else {
        context.commit('SET_STOCK_REQUEST', { data: [], total: 0 })
      }
      return res
    }).catch((e) => {
      return {
        status: false,
        data: e
      }
    })
  },
  async STOCK_REQUEST_GET_ONE (context, pid) {
    return request.get('product/stock-request/' + pid).then((res) => {
      return res
    }).catch((e) => {
      return {
        status: false,
        data: e
      }
    })
  },
  async STOCK_REQUEST_PROCESS (context, params) {
    return request.post('product/stock-request', params).then((res) => {
      return res
    }).catch((e) => {
      return {
        status: false,
        data: e
      }
    })
  },
  async STOCK_OU_HISTORY (context, q) {
    return request.get('product/stock-ou-history' + (q || '')).then((res) => {
      return res
    }).catch((e) => {
      return {
        status: false,
        data: e
      }
    })
  },
  async STOCK_OU_HISTORY_PROCESS (context, params) {
    return request.post('product/stock-ou-history', params).then((res) => {
      return res
    }).catch((e) => {
      return {
        status: false,
        data: e
      }
    })
  },
  async STOCK_OU_HISTORY_DELETE (context, id) {
    return request.delete('product/stock-ou-history/' + (parseInt(id || 0))).then((res) => {
      return res
    }).catch((e) => {
      return {
        status: false,
        data: e
      }
    })
  },
  async STOCK_MOVING_GET (context, param) {
    return request.get('product/stock-moving/' + (param.product_spec || 0) + '/' + param.type + (param.q || '')).then((res) => {
      return res
    }).catch((e) => {
      return {
        status: false,
        data: e
      }
    })
  },
  async STOCK_MOVING_PROCESS (context, params) {
    return request.post('product/stock-moving', params).then((res) => {
      return res
    }).catch((e) => {
      return {
        status: false,
        data: e
      }
    })
  },
  async STOCK_MOVING_DELETE (context, id) {
    return request.delete('product/stock-moving/' + (id || 0)).then((res) => {
      return res
    }).catch((e) => {
      return {
        status: false,
        data: e
      }
    })
  },
  async STOCK_MOVE_PROCESS (context, params) {
    return request.post('product/stock-move-process', params).then((res) => {
      return res
    }).catch((e) => {
      return {
        status: false,
        data: e
      }
    })
  },
  async STOCK_REQUEST_IN_HISTORY_GET (context, stockRequestID) {
    return request.get('product/stock-in/' + (stockRequestID || 0)).then((res) => {
      return res
    }).catch((e) => {
      return {
        status: false,
        data: e
      }
    })
  },
  async STOCK_REQUEST_IN_HISTORY_PROCESS (context, params) {
    return request.post('product/stock-in', params).then((res) => {
      return res
    }).catch((e) => {
      return {
        status: false,
        data: e
      }
    })
  },
  async TRX_PAYMENT_DELETE (context, id) {
    return request.delete('product/stock-in/' + id).then(res => res).catch((e) => {
      return {
        status: false,
        data: e
      }
    })
  },
  async SUPPLIER_GET (context, q) {
    return request.get('product/supplier' + (q || '')).then((res) => {
      return res
    }).catch((e) => {
      return {
        status: false,
        data: e
      }
    })
  },
  async SUPPLIER_PROCESS (context, params) {
    return request.post('product/supplier', params).then((res) => {
      return res
    }).catch((e) => {
      return {
        status: false,
        data: e
      }
    })
  },
  async STOCK_PROCESS (context, params) {
    return request.post('product/stock-request/stock', params).then((res) => {
      return res
    }).catch((e) => {
      return {
        status: false,
        data: e
      }
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
