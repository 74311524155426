import request from '../_api/request'

const state = {
}

const mutations = {
}

const actions = {
  async GET_PROVINCE (context) {
    return await request.get('shipping/province')
      .then(res => res)
      .catch((r) => {
        return null
      })
  },
  async GET_CITY (context, provid) {
    return await request.get('shipping/city?province_id=' + provid)
      .then(res => res)
      .catch(r => r)
  },
  async GET_DISTRICT (context, citid) {
    return await request.get('shipping/kecamatan?city=' + citid)
      .then(res => res)
      .catch(err => err)
  },
  async SHIPPING_CHECK (context, params) {
    return await request.post('shipping', params)
      .then(res => res)
      .catch(err => err)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
