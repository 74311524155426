import Vue from 'vue'
import Vuex from 'vuex'
import request from './_api/request'
import socket from './modules/socket'
import product from './modules/product'
import customer from './modules/customer'
import trx from './modules/trx'
import shipping from './modules/shipping'
import account from './modules/account'
import feature from './modules/feature'

Vue.use(Vuex)

let toastTimeout = null
export default new Vuex.Store({
  state: {
    appVersion: 'Beta v0.0.2',
    user: null,
    storeData: [],
    storeActive: 0,
    toast: {
      show: false,
      message: '',
      class: 'ld ld-slide-ttb-in'
    },
    map: {
      show: false,
      data: null,
      setPoint: {
        active: false,
        draggable: null,
        data: null
      }
    },
    payment: [
      // { id: 'cash', name: 'Cash' },
      // { id: 'card', name: 'EDC/Card' },
      // { id: 'qris', name: 'Qris' },
      { id: 'bank_transfer', name: 'Bank Transfer', customerRequired: true },
      // { id: 'online', name: 'Xendit Payment', customerRequired: true },
      { id: 'cash', name: 'Cash', customerRequired: true }
    ]
  },
  mutations: {
    MAP (state, v) {
      state.map.show = v.show || false
      state.map.data = v.data || null
      state.map.setPoint.active = v.setPoint ? v.setPoint.active : null
      state.map.setPoint.data = v.setPoint ? v.setPoint.data : null
      state.map.setPoint.draggable = v.setPoint ? v.setPoint.draggable || null : null
    },
    USER (state, v) {
      state.user = v || null
    },
    STORE_DATA (state, v) {
      state.storeData = v || []
    },
    STORE_ACTIVE (state, v) {
      state.storeActive = parseInt(v) || 0
    },
    TOAST (state, v) {
      state.toast.class = 'ld ld-slide-ttb-in'
      state.toast.show = v.show
      state.toast.message = v.message
    }
  },
  actions: {
    async RESET_DATA (context, params) {
      return await request.post('reset', params)
        .then(res => res)
        .catch(() => {
          return null
        })
    },
    async PRICE_LIST_DOWNLOAD (context, customer) {
      return await request.post('product/price-list/download', { customer })
        .then(res => res)
        .catch(() => {
          return null
        })
    },
    async AUTH_LOGIN (context, params) {
      return await request.post('dashboard', params)
        .then(res => res)
        .catch(() => {
          return null
        })
    },
    async ME (context, query) {
      return request.get('user').then((res) => {
        if (res.status) {
          context.commit('USER', res.data.data)
          const role = res.data.data.role
          if (role === 'root' || role === 'admin') {
            context.commit('STORE_ACTIVE', 0)
          } else {
            context.commit('STORE_ACTIVE', parseInt(res.data.data.store))
            if (role === 'driver') {
              context.dispatch('trx/TRX_GET', '?store=' + parseInt(res.data.data.store) + '&driver=' + res.data.data.id + '&driverStatus=')
            }
          }
        } else {
          context.commit('USER', null)
        }
        return res
      }).catch((e) => {
        return null
      })
    },
    async ME_UPDATE (context, params) {
      return await request.put('/user/me', params).then(res => res).catch(err => err)
    },
    async AREA_GET (context, q) {
      return await request.get('/shipping/area' + (q || '')).then(res => res).catch(err => err)
    },
    async STORE (context) {
      return request.get('store').then((res) => {
        if (res.status) {
          context.commit('STORE_DATA', res.data.data || [])
        } else {
          context.commit('STORE_DATA', [])
        }
        return res
      }).catch((e) => {
        return {
          status: false,
          data: e
        }
      })
    },
    async STORE_PROCESS (context, params) {
      return request.post('store', params).then((res) => {
        return res
      }).catch((e) => {
        return {
          status: false,
          data: e
        }
      })
    },
    async UPLOAD_FILE (context, params) {
      params.source = process.env.NODE_ENV === 'production' ? (params.source || 'others') : 'localhost'
      return await request.post('upload', params).then(res => res)
    },
    TOAST (context, params) {
      if (toastTimeout) {
        clearInterval(toastTimeout)
      }
      context.commit('TOAST', params)
      if (params.show) {
        toastTimeout = setInterval(() => {
          setTimeout(() => {
            params.show = false
            params.message = ''
            context.commit('TOAST', params)
          }, 300)
          clearInterval(toastTimeout)
        }, 4000)
      }
    }
  },
  modules: {
    socket,
    product,
    customer,
    trx,
    shipping,
    account,
    feature
  }
})
