<template>
  <div v-if="$role(['director', 'service_advisor', 'head_operation', 'operation_unit', 'logistic'], $store.state.user.role)" class="py-4">
    <v-row>
      <v-col
        v-for="[icon, text, slug] in menus.filter(r => $role(r[3], $store.state.user.role))"
        :key="icon + slug"
        cols="12"
        class="col-sm-3 px-4"
      >
        <v-card :to="slug" class="d-flex align-center justify-center pa-4 primary white--text">
          <v-icon class="mr-2" dark>
            {{ icon }}
          </v-icon>
          {{ text }}
        </v-card>
      </v-col>
      <v-col v-if="$role([], $store.state.user.role)" cols="12" class="d-flex flex-column align-center justify-center pt-16">
        <v-btn depressed large class="rounded-lg py-8 px-8 font-weight-bold mt-16" color="red darken-2" dark @click.prevent="UploadData()">
          IMPORT NEW DATA
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import readXlsxFile from 'read-excel-file'
export default {
  name: 'Home',
  data: () => ({
    menus: [
      ['mdi-apps', 'Dashboard', '/', ['director', 'service_advisor', 'head_operation', 'operation_unit', 'logistic']],
      ['mdi-tools', 'Products', '/inventory', ['director', 'service_advisor', 'head_operation', 'operation_unit', 'logistic']],
      ['mdi-network-pos', 'Order & Service', '/transaction', ['director', 'service_advisor', 'head_operation', 'operation_unit', 'logistic']],
      ['mdi-car-shift-pattern', 'Logistic', '/logistic', ['director', 'service_advisor', 'head_operation', 'logistic']],
      ['mdi-car-clock', 'Vehicles Data', '/vehicle', ['director', 'service_advisor', 'head_operation']],
      ['mdi-account-multiple', 'Customer', '/customer', ['director', 'service_advisor', 'head_operation']],
      ['mdi-inbox-arrow-down', 'Operation Units', '/store-manager', ['director', 'service_advisor', 'head_operation', 'logistic']],
      ['mdi-shield-account-outline', 'Account Manager', '/account-manager', ['director', 'head_operation']],
      ['mdi-cash-multiple', 'Finance', '/finance', ['director', 'head_operation']]
    ],
    sheets: {
      productTemp: [],
      customer: [],
      product: [],
      service: [],
      pricing: []
    },
    stepGenerated: 0
  }),
  methods: {
    isGenerated () {
      this.stepGenerated = this.stepGenerated + 1
      if (this.stepGenerated === 4) {
        console.log('finish')
        const productTemp = this.sheets.productTemp
        const product = []
        let productRowActive = null
        for (let i = 0; i < productTemp.length; i++) {
          const row = productTemp[i]
          if (row.PRODUCT_NAME) {
            productRowActive = (row.PRODUCT_NAME || '').trim()
          }
          const productIndex = product.findIndex(r => (r.name || '').trim() === (productRowActive || '').trim())
          if (productIndex > -1) {
            const item = {
              sku: row.SKU || '',
              sku2: row.SKU2 || '',
              merk: row.PRODUCT_BRAND || product[productIndex].category || '',
              name: row.VARIANT_NAME || '',
              description: '',
              price_initial: parseInt(row.BUY_PRICE) || 0,
              price: parseInt(row.SELL_PRICE) || 0,
              weight: parseInt(row.WEIGHT_GRAM) || 0,
              status: 1,
              stock_warehouse: parseInt(row.STOCK_WAREHOUSE) || 0,
              stocks: row.STOCKS
            }
            product[productIndex].items.push(item)
          } else {
            const item = [{
              sku: row.SKU || '',
              sku2: row.SKU2 || '',
              merk: row.PRODUCT_BRAND || '',
              name: row.VARIANT_NAME || '',
              description: '',
              price_initial: parseInt(row.BUY_PRICE) || 0,
              price: parseInt(row.SELL_PRICE) || 0,
              weight: parseInt(row.WEIGHT_GRAM) || 0,
              status: 1,
              stock_warehouse: parseInt(row.STOCK_WAREHOUSE) || 0,
              stocks: row.STOCKS
            }]
            product.push({
              name: row.PRODUCT_NAME || '',
              is_service: 0,
              category: row.PRODUCT_BRAND || '',
              description: '',
              galleries: '[]',
              status: 1,
              items: item
            })
          }
        }
        this.sheets.product = product
        this.PROCESS_RESET()
      } else {
        console.log('process')
      }
    },
    PROCESS_RESET () {
      const data = Object.assign({}, this.sheets)
      delete data.productTemp
      this.$store.dispatch('RESET_DATA', data)
        .then((res) => {
          if (res.status) {
            if (res.data.data.status) {
              this.$store.dispatch('TOAST', { show: true, message: 'Berhasil di import!' })
            } else {
              this.$store.dispatch('TOAST', { show: true, message: 'Gagal, silahkan coba lagi!' })
            }
          } else {
            this.$store.dispatch('TOAST', { show: true, message: 'Ops, terjadi kesalahan!' })
          }
        })
    },
    parsingStock (row) {
      const stocks = []
      const unitLength = (row.length - 10) / 2
      for (let s = 0; s < unitLength; s++) {
        const indexName = 10 + (s * 2)
        const indexStock = 11 + (s * 2)
        const name = (row[indexName] || '').trim()
        const quantity = parseInt(row[indexStock]) || 0
        stocks.push({
          name,
          quantity
        })
      }
      return stocks
    },
    UploadData () {
      if (!confirm('Upload data Product, Stock, Pricing, etc ?')) {
        return false
      }
      this.sheets.productTemp = []

      this.sheets.customer = []
      this.sheets.product = []
      this.sheets.service = []
      this.sheets.pricing = []

      let headerCustomer = []
      let headerProduct = []
      let headerService = []
      let headerPricing = []

      this.stepGenerated = 0
      const elInputExist = document.getElementById('inputexcel')
      if (elInputExist) {
        elInputExist.parentNode.removeChild(elInputExist)
      }
      const elInput = document.createElement('input')
      elInput.setAttribute('type', 'file')
      elInput.setAttribute('class', 'file-upload')
      elInput.setAttribute('id', 'inputexcel')
      elInput.addEventListener('change', () => {
        readXlsxFile(elInput.files[0], { sheet: 'CUSTOMER' }).then((rows) => {
          headerCustomer = rows[0]
          for (let r = 0; r < rows.length; r++) {
            const row = rows[r]
            if (r > 0 && this.validRow(row)) {
              this.setRow('customer', headerCustomer, row)
            }
          }
          this.isGenerated()
        })
        readXlsxFile(elInput.files[0], { sheet: 'PRODUCT' }).then((rows) => {
          headerProduct = rows[0]
          for (let r = 0; r < rows.length; r++) {
            const row = rows[r]
            if (r > 0 && this.validRow(row)) {
              this.setRow('productTemp', headerProduct, row)
            }
          }
          this.isGenerated()
        })
        readXlsxFile(elInput.files[0], { sheet: 'SERVICE' }).then((rows) => {
          headerService = rows[0]
          for (let r = 0; r < rows.length; r++) {
            const row = rows[r]
            if (r > 0 && this.validRow(row)) {
              this.setRow('service', headerService, row)
            }
          }
          this.isGenerated()
        })
        readXlsxFile(elInput.files[0], { sheet: 'PRICING' }).then((rows) => {
          headerPricing = rows[0]
          for (let r = 0; r < rows.length; r++) {
            const row = rows[r]
            if (r > 0 && this.validRow(row)) {
              this.setRow('pricing', headerPricing, row)
            }
          }
          this.isGenerated()
        })
      })
      elInput.click()
    },
    setRow (type, header, row) {
      const newRow = {}
      for (let d = 0; d < row.length; d++) {
        if (type === 'productTemp') {
          if (d < 10) {
            newRow[header[d]] = row[d]
          }
        } else {
          newRow[header[d]] = row[d]
        }
      }
      if (type === 'productTemp') {
        newRow.STOCKS = this.parsingStock(row)
      }
      this.sheets[type].push(newRow)
    },
    validRow (data) {
      let isNotEmpty = false
      for (let p = 0; p < data.length; p++) {
        if (!isNotEmpty && data[p]) {
          isNotEmpty = true
        }
      }
      return isNotEmpty
    }
  }
}
</script>
