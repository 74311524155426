import request from '../_api/request'

const state = {
  accounts: {
    data: [],
    total: 0
  }
}

const mutations = {
  SET_ACCOUNTS (state, v) {
    state.accounts.data = v.data || []
    state.accounts.total = parseInt(v.total) || 0
  }
}

const actions = {
  async ACCOUNTS_GET (context, query) {
    return request.get('user/accounts' + (query || '')).then((res) => {
      return res
    }).catch((e) => {
      return {
        status: false,
        data: e
      }
    })
  },
  async ACCOUNT_PROCESS (context, params) {
    return request.post('user/accounts', params).then((res) => {
      return res
    }).catch((e) => {
      return {
        status: false,
        data: e
      }
    })
  },
  async ACCOUNTS_ATTENDANCE_GET (context, query) {
    return request.get('user/attendance' + (query || '')).then((res) => {
      return res
    }).catch((e) => {
      return {
        status: false,
        data: e
      }
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
