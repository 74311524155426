<template>
  <v-app id="inspire">
    <Toast />
    <Auth v-if="!$store.state.user" />
    <v-navigation-drawer
      v-model="drawer"
      app
    >
      <v-sheet
        color="primary"
        class="pa-4"
        dark
        v-if="$store.state.user"
      >
        <div class="d-flex fill-width align-center justify-space-between">
          <span class="my-4 d-flex align-center text-truncate font-weight-bold" style="max-width:100%;">
            <v-icon color="" large class="mr-2">
              mdi-account-circle
            </v-icon>
            <div class="text-truncate" style="max-width: 100%;">
              <div class="d-flex align-start">
                {{ $store.state.user.name }}
                <v-chip x-small class="text-capitalize ml-2" color="orange">{{ ($store.state.user.role.replace('_', ' ')) }}</v-chip>
              </div>
              <small class="d-block font-weight-regular">{{ $store.state.user.email }}</small>
              <div class="pt-1 grey--text text--lighten-2 font-weight-regular" style="font-size: .6rem;">
                App Version : {{ $store.state.appVersion || '-' }}
              </div>
            </div>
          </span>
        </div>
      </v-sheet>

      <v-divider />

      <v-list v-if="$store.state.user">
        <template v-for="[icon, text, slug] in links.filter(r => $role(r[3], $store.state.user.role))">
          <v-list-item
            :key="icon"
            :to="slug"
            link
          >
            <v-list-item-icon>
              <v-icon>{{ icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ text }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
        <v-divider />
        <v-list-item v-if="$role(['director'], $store.state.user.role)" to="/features/main-banner" link>
          <v-list-item-icon>
            <v-icon>mdi-feature-search-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Apps Media</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider />
        <v-list-item link @click.prevent="DO_LOGOUT()">
          <v-list-item-icon>
            <v-icon>mdi-power</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Logout</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-main v-if="$store.state.user">
      <v-container
        v-if="$role(['director', 'service_advisor', 'head_operation', 'operation_unit', 'logistic'], $store.state.user.role)"
        class="py-2 px-6"
        fluid
      >
        <router-view />
      </v-container>
    </v-main>
    <v-btn fab bottom right fixed :color="drawer ? 'red' : 'indigo'" dark class="hidden-sm-and-up" @click.prevent="drawer = !drawer">
      <v-icon>
        {{ drawer ? 'mdi-close' : 'mdi-menu' }}
      </v-icon>
    </v-btn>
  </v-app>
</template>

<script>

export default {
  data: () => ({
    drawer: null,
    links: [
      ['mdi-apps', 'Dashboard', '/', ['director', 'service_advisor', 'head_operation', 'operation_unit', 'logistic']],
      ['mdi-tools', 'Products', '/inventory', ['director', 'service_advisor', 'head_operation', 'operation_unit', 'logistic']],
      ['mdi-network-pos', 'Order & Service', '/transaction', ['director', 'service_advisor', 'head_operation', 'operation_unit', 'logistic']],
      ['mdi-car-shift-pattern', 'Logistic', '/logistic', ['director', 'service_advisor', 'head_operation', 'logistic']],
      ['mdi-car-clock', 'Vehicles Data', '/vehicle', ['director', 'service_advisor', 'head_operation']],
      ['mdi-account-multiple', 'Customer', '/customer', ['director', 'service_advisor', 'head_operation']],
      ['mdi-inbox-arrow-down', 'Operation Units', '/store-manager', ['director', 'service_advisor', 'head_operation', 'logistic']],
      ['mdi-shield-account-outline', 'Account Manager', '/account-manager', ['director', 'head_operation']],
      ['mdi-cash-multiple', 'Finance', '/finance', ['director', 'head_operation']]
    ]
  }),
  mounted () {
  },
  methods: {
    DO_LOGOUT () {
      if (!confirm('Logout ?')) {
        return false
      }
      localStorage.removeItem('t')
      this.$store.commit('USER', null)
    }
  }
}
</script>

<style lang="scss">
.c-pointer {
  cursor: pointer
}
.fill-width {
  width: 100%!important;
}
.file-upload {
  position:fixed;
  display: block;
  bottom: 1px;
  left: calc(100vw - 1px);
  width: 1px;
  height: 1px;
  overflow: hidden;
}

@media screen {
  // .dialog-printed {
  //   display: none;
  // }
}

@media print {
  html {
    &.overflow-y-hidden {
      overflow: auto!important;
    }
  }
  body {
    * {
      &:not(.v-application) {
        visibility:hidden;
      }
    }
  }
  .v-application {
    width:100%!important;
    >div {
      &:not([aria-modal="true"]) {
        display: none!important;
      }
    }
  }
  .container--fluid {
    height:auto!important;
  }
  .v-dialog__content {
    height: auto!important;
    max-height: unset!important;
    width: 100%!important;
    position: relative!important;
    align-items: flex-start!important;
    justify-content: start!important;
    .v-dialog {
      &:not(.v-dialog--fullscreen) {
        max-height: unset!important;
        &.dialog-printed {
          visibility:hidden!important;
          border-radius: 0px!important;
          margin: 0px!important;
          width:100%!important;
          >.v-card,
          >.v-card * {
            visibility: visible;
            overflow: visible !important;
          }
          >.v-card {
            box-shadow: none!important;
            position:absolute;
            left:0;
            top:0;
          }
          .no-print {
            display: none!important;
            visibility:hidden;
          }
        }
      }
    }
  }
}
table {
  &.table-print {
    border-spacing: 0px;
    border-collapse: collapse;  /* <--- add this so all the internal <td>s share adjacent borders  */
    border: 1px solid black;
    tr {
      border: 1px solid rgba(0, 0, 0, .5);
      border-collapse: collapse;
      border-spacing: 0px;
      td {
        border: 1px solid rgba(0, 0, 0, .5);
      }
    }
  }
}
</style>
